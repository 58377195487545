<template>
  <span>
    <span v-if="!selectedAuthorities.length">Wählen Sie mindestens einen Standort aus!</span>
    <span v-else-if="!selectedCategories.length">Wählen Sie mindestens einen Impfstoff / ein Beratungsgespräch aus!</span>
    <span v-else-if="!filteredAppointments.length">Leider wurden keine Termine gefunden!</span>
    <span v-else>
      <span v-if="filteredAppointments.length === 1">
        Ein Termin
        <span v-if="freeSlots === 1">mit einem freien Platz</span>
        <span v-else>mit {{ freeSlots.toLocaleString('de') }} freien Plätzen</span>
      </span>
      <span v-else>{{ filteredAppointments.length }} Termine mit {{ freeSlots.toLocaleString('de') }} freien Plätzen</span>
      bei
      <span v-if="selectedAuthorities.length === 1">einem Standort</span>
      <span v-else> {{ selectedAuthorities.length.toLocaleString('de') }} Standorten</span>
      gefunden!
    </span>
  </span>
</template>


<script>
  import {mapGetters, mapState} from 'vuex'

  export default {
    computed: {
      ...mapState(['selectedAuthorities', 'selectedCategories']),
      ...mapGetters(['filteredAppointments']),
      freeSlots() {
        return this.filteredAppointments.map(o => o.freeSlots).reduce((a, c) => a + c)
      }
    }
  }
</script>
