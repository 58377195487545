<template>
  <div v-if="filteredAppointments.length">
    <table class="table d-none d-md-table">
      <thead>
        <th>Standort</th>
        <th>Termin</th>
        <th>Impfstoff / Beratungsgespräch</th>
        <th>Plätze</th>
      </thead>
      <tbody>
        <tr v-for="(appointment, index) in limitedFilteredAppointments" :key="index">
          <td>{{ authorityById(appointment.authorityId).name }}</td>
          <td>{{ appointment.startDate | toDateString('dd, DD.MM.YYYY HH:mm') }}</td>
          <td>
            <span v-if="categoryById(appointment.categoryId).colorCode" class="category-color-dot" :style="`background-color: #${ categoryById(appointment.categoryId).colorCode };`"></span>
            {{ categoryById(appointment.categoryId).description }}
          </td>
          <td>{{ appointment.freeSlots }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row d-md-none">
      <div v-for="(appointment, index) in limitedFilteredAppointments" :key="index" class="col-12 col-sm-6 mb-2">
        <div class="card result-card h-100">
          <div class="card-body h-100">
            <span class="category-color-bar" :style="categoryById(appointment.categoryId).colorCode ? `background-color: #${categoryById(appointment.categoryId).colorCode}` : ''"></span>
            <h4 class="font-md font-weight-bold lineheight-small mb-2 d-block"><strong>{{ appointment.startDate | toDateString('dd, DD.MM.YYYY HH:mm') }}</strong></h4>
            <span class="position-relative">
              <span v-if="categoryById(appointment.categoryId).colorCode" class="category-color-dot" :style="`background-color: #${categoryById(appointment.categoryId).colorCode}`"></span>
              <span class="category-name">{{ categoryById(appointment.categoryId).description }}</span>
            </span>
            <div>{{ authorityById(appointment.authorityId).name }}</div>
            <div><b>Plätze:</b> {{ appointment.freeSlots }}</div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary btn-sm btn-block" @click="isLimited = false" v-if="isLimited && needsLimiting">
      Alle Termine anzeigen
    </button>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {mapFields} from "vuex-map-fields";

  export default {
    data() {
      return {
        isLimited: true,
        limitSize: 250,
      }
    },
    computed: {
      limitedFilteredAppointments () {
        if (this.isLimited && this.needsLimiting) {
          return this.filteredAppointments.slice(0,this.limitSize)
        }
        return this.filteredAppointments;
      },
      needsLimiting () {
        return this.filteredAppointments.length > this.limitSize;
      },
      ...mapFields(['selectedAuthorities', 'selectedCategories']),
      ...mapGetters(['filteredAppointments', 'authorityById', 'categoryById'])
    },
    watch: {
      selectedAuthorities() {
        this.isLimited = true
      },
      selectedCategories () {
        this.isLimited = true
      }
    }
  }
</script>

<style lang="scss">

#jaukerl-linz {
  .category-color-bar {
    display: inline-block;
    height: 100%;
    width: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .category-color-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
  .result-card .card-body {
    padding: 15px 20px 15px 25px;
  }
}
</style>

