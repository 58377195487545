<template>
  <b-form-group v-if="availableCategories.length" label="Impfstoffe / Beratungsgespräche:">
    <b-form-checkbox-group id="categories" v-model="selectedCategories" name="authorities">
      <b-row align-v="stretch">
        <b-col v-for="(category, index) in availableCategories" :key="index" class="mb-3" lg="6" md="6">
          <div
            :class="`card card-checkbox pointer h-100 ${isCategoryChecked(category.id) ? ' text-white bg-secondary card-checkbox--active' : ''}`">
            <b-form-checkbox :value="category.id" class="h-100">
              <div class="card-body h-100">
                <h5 class="card-title">
                  <span v-if="category.colorCode" class="category-color-dot" :style="`background-color: #${category.colorCode};`"></span>
                  {{ category.description }}
                </h5>
                Termine: {{
                  appointments.filter(o => o.categoryId === parseInt(category.id)).length.toLocaleString('de')
                }} /
                Plätze: {{
                  appointments.filter(o => o.categoryId === parseInt(category.id)).map(o => o.freeSlots).reduce((a, c) => a + c).toLocaleString('de')
                }}
              </div>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </b-form-checkbox-group>
  </b-form-group>
</template>


<script>
import {mapFields} from 'vuex-map-fields';
import {mapGetters, mapState} from 'vuex'

import {BCol, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BRow} from "bootstrap-vue";


export default {
  components: {BCol, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BRow},
  computed: {
    ...mapFields(['selectedCategories']),
    ...mapState(['appointments']),
    ...mapGetters(['availableCategories', 'isCategoryChecked'])
  }
}
</script>


<style lang="scss">
#jaukerl-linz {
  .category-color-dot {
    position: relative;
    display: inline-block;
    top: 1px;
    height: 18px;
    width: 18px;
    border-radius: 100px;
    margin-right: 3px;
  }

  .custom-checkbox .custom-control-input ~ .custom-control-label::before {
    left: 20px;
    top: 23px;
    width: .3125em;
    height: .625em;
    border: solid #0273a7;
    border-width: 0 .125em .125em 0;
    transform: rotate(45deg);
    z-index: 1;
    display: none;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .card {
    background: #0000;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #fff;
    display: block;
  }

  .custom-checkbox .custom-control-input ~ .custom-control-label::after {
    background: #ccc;
    margin-left: 38px;
    margin-top: 17px;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background: #fff
  }

  .card-checkbox:hover .custom-checkbox .custom-control-input:not(:checked) ~ .custom-control-label::after {
    background: #afafaf;
  }

  .card-checkbox--active {
    border-color: #0273a7;
  }

  .card-checkbox {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-checkbox:focus-within {
    outline: 2px solid #cb2276;
    outline-offset: 1px;
  }

  .btn:focus {
    outline: 2px solid #cb2276;
    outline-offset: 1px;
  }

  .card-checkbox .custom-checkbox {
    margin: 0;
    padding: 0;

  }

  .card-checkbox .custom-control-label {
    width: 100%;
    padding: 0 10px 0 20px;
  }

  .result-card {
    border-radius: 0.4rem 0;
    overflow: hidden;
  }
}
</style>
