import Vue from 'vue'
import App from './App.vue'
import store from './store'

import dayjs from "dayjs";
import "dayjs/locale/de"

Vue.config.productionTip = false

Vue.filter('toDateString', function(date, format) {
  if (!date || date === 0) return ''
  format = format || "dd, DD.MM.YYYY HH:mm:ss"
  return dayjs(date).locale('de').format(format)
})

new Vue({
  store,
  render: h => h(App)
}).$mount('#jaukerl-linz')
