import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import {getField, updateField} from "vuex-map-fields";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    appointments: [],
    authorities: [],
    fetchedAt: null,
    categories: [],
    selectedAuthorities: [],
    selectedCategories: [],
  },
  getters: {
    categoryById(state) {
      return id => state.categories.find(o => id === o.id)
    },
    authorityById(state) {
      return id => state.authorities.find(o => id === o.id)
    },
    filteredAppointments(state) {
      if (state.isLoading) return []
      return state.appointments.filter((appointment) => {
        return state.selectedAuthorities.includes(appointment.authorityId)
          && state.selectedCategories.includes(appointment.categoryId)
      })
    },
    availableCategories(state, getters) {
      return state.categories.filter(o => {
        return getters.availableCategoryIds.includes(o.id)
      })
    },
    availableCategoryIds(state) {
      return [...new Set(state.appointments.map(o => o.categoryId))]
    },
    allAuthorityIds(state) {
      return state.authorities.map(o => o.id)
    },
    isAuthorityChecked(state) {
      return (authorityId) => {
        for (let i = 0; i < state.selectedAuthorities.length; i++) {
          if (parseInt(authorityId) === parseInt(state.selectedAuthorities[i])) return true
        }
        return false
      }
    },
    isCategoryChecked(state) {
      return (categoryId) => {
        for (let i = 0; i < state.selectedCategories.length; i++) {
          if (parseInt(categoryId) === parseInt(state.selectedCategories[i])) return true
        }
        return false
      }
    },
    getField,
  },
  mutations: {
    updateField,
    set(state, [name, value]) {
      state[name] = value
    },
  },
  actions: {
    selectAllAuthorities(context) {
      context.commit('set', ['selectedAuthorities', context.getters.allAuthorityIds])
    },
    deselectAllAuthorities(context) {
      context.commit('set', ['selectedAuthorities', []])
    },
    async init(context) {
      context.commit('set', ['isLoading', true])
      const url = `https://storage.googleapis.com/jaukerl-linz/data.json?_=${+new Date()}`
      const response = await axios.get(url)

      const appointments = response.data.appointments.sort((appointment1, appointment2) => {
        return (+new Date(appointment1.startDate)) - +(new Date(appointment2.startDate))
      })

      context.commit('set', ['appointments', appointments])
      context.commit('set', ['authorities', response.data.authorities])
      context.commit('set', ['categories', response.data.categories])
      context.commit('set', ['fetchedAt', response.data.fetchedAt])

      if (!context.state.selectedCategories.length) {
        context.commit('set', ['selectedCategories', context.getters.availableCategoryIds])
      }
      if (!context.state.selectedAuthorities.length) {
        context.commit('set', ['selectedAuthorities', context.getters.allAuthorityIds])
      }
      context.commit('set', ['isLoading', false])
    }
  },
  plugins: [
    createPersistedState({
      reducer(state) {
        return {
          selectedAuthorities: state.selectedAuthorities,
          selectedCategories: state.selectedCategories,
        }
      }
    })
  ]
})
