<template>
  <div id="jaukerl-linz">
    <div v-if="isLoading || !fetchedAt" class="text-center text-secondary">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <FilterCategory/>
      <FilterAuthority/>

      <h2 class="mt-5">
        <ResultHeadline/>
      </h2>
      <Result/>
      <div class="mt-3">
        Letzte Aktualisierung: {{ new Date(fetchedAt) | toDateString }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

import FilterCategory from './components/filter-category'
import FilterAuthority from './components/filter-authority'
import ResultHeadline from './components/result-headline'
import Result from './components/result'

export default {
  components: {
    FilterCategory,
    FilterAuthority,
    ResultHeadline,
    Result,
  },
  computed: {
    ...mapState(['isLoading', 'fetchedAt']),
    ...mapGetters(['filteredAppointments'])
  },
  mounted() {
    this.$store.dispatch('init')
  }
}
</script>

<style lang="scss">

#jaukerl-linz {
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/spinners";

  .pointer {
    cursor: pointer;
  }

  .pointer * {
    cursor: pointer;
  }

}
</style>
